import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import store from '../store';
import axios from 'axios';
import moment from 'moment';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/:hash',
    name: 'LoginHash',
    component: Login,
  },
  {
    path: '/login/:hash/:hashempresa',
    name: 'LoginHashEmpresa',
    component: Login,
  },
  {
    path: '/esqueceu-senha',
    name: 'EsqueciSenha',
    component: () => import('../views/esqueciSenha/index.vue'),
  },
  {
    path: '/esqueceu-senha/:id',
    name: 'EsqueciSenhaAlterar',
    component: () => import('../views/esqueciSenha/form.vue'),
  },

  {
    path: '/registro/cookiebanner',
    name: 'RegistrarCookieBanner',
    component: () => import('../views/registrar/cookiebanner.vue'),
  },
  {
    path: '/registrar',
    name: 'Registrar',
    component: () => import('../views/registrar/etapa1.vue'),
  },
  {
    path: '/cadastrar',
    name: 'Cadastro',
    component: () => import('../views/registrar/etapa2.vue'),
  },
  {
    path: '/cadastrar/:id',
    name: 'CadastroInfo',
    component: () => import('../views/registrar/etapa3.vue'),
  },
  {
    path: '/informacoes',
    name: 'Informacoes',
    component: () => import('../views/registrar/etapa4.vue'),
  },

  {
    path: '/',
    redirect: '/painel',
  },

  {
    path: '/assinatura',
    name: 'Assinatura',
    component: () => import('../views/assinatura/index.vue'),
    meta: {requiresLogin: true, blockCliente: true },
  },
  {
    path: '/assinatura/:id',
    name: 'AssinaturaForm',
    component: () => import('../views/assinatura/form.vue'),
    meta: {requiresLogin: true, blockCliente: true },
  },
  {
    path: '/assinatura/desativado',
    name: 'AssinaturaDesativado',
    component: () => import('../views/assinatura/desativo.vue'),
    meta: {requiresLogin: true, blockCliente: true },
  },

  {
    path: '/cadastros',
    name: 'Cadastros',
    component: () => import('../views/cadastros/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true },
  },

  {
    path: '/cadastros/setores',
    name: 'Setor',
    component: () => import('../views/cadastros/setor/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/setores/form',
    name: 'setorForm',
    component: () => import('../views/cadastros/setor/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/setores/form/:id',
    name: 'setorFormEdit',
    component: () => import('../views/cadastros/setor/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/funcoes',
    name: 'Funcoes',
    component: () => import('../views/cadastros/funcoes/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/funcoes/form',
    name: 'funcoesForm',
    component: () => import('../views/cadastros/funcoes/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/funcoes/form/:id',
    name: 'funcoesFormEdit',
    component: () => import('../views/cadastros/funcoes/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/pessoas',
    name: 'Pessoas',
    component: () => import('../views/cadastros/pessoas/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/pessoas/form',
    name: 'pessoasForm',
    component: () => import('../views/cadastros/pessoas/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/pessoas/form/:id',
    name: 'pessoasFormEdit',
    component: () => import('../views/cadastros/pessoas/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/cadastros/categoriadados',
    name: 'Categoriadados',
    component: () => import('../views/cadastros/categoriadados/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/categoriadados/form',
    name: 'categoriadadosForm',
    component: () => import('../views/cadastros/categoriadados/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/categoriadados/form/:id',
    name: 'categoriadadosFormEdit',
    component: () => import('../views/cadastros/categoriadados/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/dados',
    name: 'Dados',
    component: () => import('../views/cadastros/dados/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/dados/form',
    name: 'dadosForm',
    component: () => import('../views/cadastros/dados/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/dados/form/:id',
    name: 'dadosFormEdit',
    component: () => import('../views/cadastros/dados/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/legislacoes',
    name: 'Legislacao',
    component: () => import('../views/cadastros/legislacao/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/legislacoes/form',
    name: 'legislacaoForm',
    component: () => import('../views/cadastros/legislacao/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/legislacoes/form/:id',
    name: 'legislacaoFormEdit',
    component: () => import('../views/cadastros/legislacao/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/canais',
    name: 'Canais',
    component: () => import('../views/cadastros/canais/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/canais/form',
    name: 'canaisForm',
    component: () => import('../views/cadastros/canais/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/canais/form/:id',
    name: 'canaisFormEdit',
    component: () => import('../views/cadastros/canais/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/direitos',
    name: 'direitos',
    component: () => import('../views/cadastros/direitos/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/direitos/form',
    name: 'direitosForm',
    component: () => import('../views/cadastros/direitos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/direitos/form/:id',
    name: 'direitosFormEdit',
    component: () => import('../views/cadastros/direitos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/baselegais',
    name: 'Baselegais',
    component: () => import('../views/cadastros/baselegais/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/baselegais/form',
    name: 'BaselegaisNew',
    component: () => import('../views/cadastros/baselegais/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/baselegais/form/:id',
    name: 'BaselegaisEdit',
    component: () => import('../views/cadastros/baselegais/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/tipotitulares',
    name: 'TipoTitulares',
    component: () => import('../views/cadastros/tipotitulares/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/tipotitulares/form',
    name: 'TipoTitularesForm',
    component: () => import('../views/cadastros/tipotitulares/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/tipotitulares/form/:id',
    name: 'TipoTitularesFormEdit',
    component: () => import('../views/cadastros/tipotitulares/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/finalidades',
    name: 'Finalidades',
    component: () => import('../views/cadastros/finalidades/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/finalidades/form',
    name: 'finalidadesForm',
    component: () => import('../views/cadastros/finalidades/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/finalidades/form/:id',
    name: 'finalidadesFormEdit',
    component: () => import('../views/cadastros/finalidades/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  
  {
    path: '/cadastros/agentes',
    name: 'Agente',
    component: () => import('../views/cadastros/agentes/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/armazenamento',
    name: 'Armazenamento',
    component: () => import('../views/cadastros/armazenamento/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/armazenamento/form',
    name: 'armazenamentoForm',
    component: () => import('../views/cadastros/armazenamento/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/armazenamento/form/:id',
    name: 'armazenamentoFormEdit',
    component: () => import('../views/cadastros/armazenamento/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/medidasSeguranca',
    name: 'MedidasSeguranca',
    component: () => import('../views/cadastros/medidasSeguranca/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/medidasSeguranca/form',
    name: 'medidasSegurancaForm',
    component: () => import('../views/cadastros/medidasSeguranca/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/medidasSeguranca/form/:id',
    name: 'medidasSegurancaFormEdit',
    component: () => import('../views/cadastros/medidasSeguranca/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/riscos',
    name: 'CadastroRiscos',
    component: () => import('../views/cadastros/riscos/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/ativos',
    name: 'Ativos',
    component: () => import('../views/cadastros/riscos/ativos/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/ativos/form',
    name: 'AtivosForm',
    component: () => import('../views/cadastros/riscos/ativos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/ativos/form/:id',
    name: 'AtivosFormEdit',
    component: () => import('../views/cadastros/riscos/ativos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/ameacas',
    name: 'Ameacas',
    component: () => import('../views/cadastros/riscos/ameacas/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/ameacas/form',
    name: 'AmeacasForm',
    component: () => import('../views/cadastros/riscos/ameacas/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/ameacas/form/:id',
    name: 'AmeacasFormEdit',
    component: () => import('../views/cadastros/riscos/ameacas/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/impactos',
    name: 'impactos',
    component: () => import('../views/cadastros/riscos/impactos/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/impactos/form',
    name: 'impactosForm',
    component: () => import('../views/cadastros/riscos/impactos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/impactos/form/:id',
    name: 'impactosFormEdit',
    component: () => import('../views/cadastros/riscos/impactos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/vulnerabilidades',
    name: 'Vulnerabilidades',
    component: () => import('../views/cadastros/riscos/vulnerabilidades/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/vulnerabilidades/form',
    name: 'vulnerabilidadesForm',
    component: () => import('../views/cadastros/riscos/vulnerabilidades/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/vulnerabilidades/form/:id',
    name: 'vulnerabilidadesFormEdit',
    component: () => import('../views/cadastros/riscos/vulnerabilidades/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/condutasmitigacao',
    name: 'Condutasmitigacao',
    component: () => import('../views/cadastros/riscos/condutasmitigacao/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/condutasmitigacao/form',
    name: 'condutasmitigacaoForm',
    component: () => import('../views/cadastros/riscos/condutasmitigacao/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/riscos/condutasmitigacao/form/:id',
    name: 'condutasmitigacaoFormEdit',
    component: () => import('../views/cadastros/riscos/condutasmitigacao/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/preatividade',
    name: 'Preatividade',
    component: () => import('../views/cadastros/preatividade/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/preatividade/form',
    name: 'PreatividadeForm',
    component: () => import('../views/cadastros/preatividade/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/preatividade/form/:id',
    name: 'PreatividadeFormEdit',
    component: () => import('../views/cadastros/preatividade/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/agentestratamento',
    name: 'Agentestratamento',
    component: () => import('../views/cadastros/agentestratamento/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/agentestratamento/form',
    name: 'AgentestratamentoForm',
    component: () => import('../views/cadastros/agentestratamento/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/agentestratamento/form/:id',
    name: 'AgentestratamentoFormEdit',
    component: () => import('../views/cadastros/agentestratamento/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/titulares',
    name: 'Titulares',
    component: () => import('../views/cadastros/titulares/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/titulares/form',
    name: 'TitularesForm',
    component: () => import('../views/cadastros/titulares/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/cadastros/titulares/form/:id',
    name: 'TitularesFormEdit',
    component: () => import('../views/cadastros/titulares/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/cadastros/profissionais',
    name: 'Profissionais',
    component: () => import('../views/cadastros/profissionais/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true, requiresEmpresa: true},
  },
  {
    path: '/cadastros/profissionais/form',
    name: 'profissionaisForm',
    component: () => import('../views/cadastros/profissionais/form.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true, requiresEmpresa: true},
  },
  {
    path: '/cadastros/profissionais/form/:id',
    name: 'profissionaisFormEdit',
    component: () => import('../views/cadastros/profissionais/form.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true },
  },
  {
    path: '/cadastros/profissionais/config/:id',
    name: 'profissionaisConfigEdit',
    component: () => import('../views/cadastros/profissionais/config.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true, requiresEmpresa: true},
  },
  {
    path: '/cadastros/profissionais/convidar/',
    name: 'profissionaisConvidar',
    component: () => import('../views/cadastros/profissionais/convidar.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true},
  },

  {
    path: '/cadastros/whitelabel',
    name: 'Whitelabel',
    component: () => import('../views/cadastros/whitelabel/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true},
  },


  {
    path: '/painel',
    name: 'Painel',
    component: () => import('../views/painel/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true },
  },
  {
    path: '/painel/form',
    name: 'PainelForm',
    component: () => import('../views/painel/form.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true, blockProfissional: true },
  },
  {
    path: '/painel/form/:id',
    name: 'PainelFormEdit',
    component: () => import('../views/painel/form.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true, blockProfissional: true },
  },
  {
    path: '/painel/desativadas',
    name: 'PainelDesativados',
    component: () => import('../views/painel/desativadas.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true, blockProfissional: true },
  },
  {
    path: '/painel/info/:id',
    name: 'PainelInfo',
    component: () => import('../views/painel/info.vue'),
    meta: {requiresLogin: true, requiresPlano: true, blockCliente: true},
  },

  {
    path: '/mapeamento',
    name: 'Mapeamento',
    component: () => import('../views/mapeamento/index.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/mapeamento/gerar',
    name: 'mapeamentoGerar',
    component: () => import('../views/mapeamento/gerarQMA.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/mapeamento/importar',
    name: 'mapeamentoImportar',
    component: () => import('../views/mapeamento/importar.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/mapeamento/form',
    name: 'mapeamentoForm',
    component: () => import('../views/mapeamento/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/mapeamento/form/:id',
    name: 'mapeamentoFormEdit',
    component: () => import('../views/mapeamento/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/mapeamento/evidencias/:id',
    name: 'mapeamentoEvidenciasEdit',
    component: () => import('../views/mapeamento/evidencias.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/projetos',
    name: 'Projetos',
    component: () => import('../views/projetos/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true},
  },
  {
    path: '/projetos/form',
    name: 'ProjetosForm',
    component: () => import('../views/projetos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/projetos/form/:id',
    name: 'ProjetosFormEdit',
    component: () => import('../views/projetos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/projetos/arquivadas',
    name: 'ProjetosArquivadas',
    component: () => import('../views/projetos/arquivadas.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true},
  },
  {
    path: '/projetos/fases',
    name: 'ProjetosFases',
    component: () => import('../views/projetos/fases.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/riscos',
    name: 'Riscos',
    component: () => import('../views/riscos/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true},
  },
  {
    path: '/riscos/form',
    name: 'RiscosForm',
    component: () => import('../views/riscos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/riscos/form/:id',
    name: 'RiscosFormEdit',
    component: () => import('../views/riscos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/requisicao',
    name: 'Requisicao',
    component: () => import('../views/requisicao/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true},
  },
  {
    path: '/requisicao/direitos',
    name: 'RequisicaoDireitos',
    component: () => import('../views/requisicao/direitos.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true},
  },
  {
    path: '/requisicao/analisar/:id',
    name: 'RequisicaoAnalisar',
    component: () => import('../views/requisicao/analisar.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/requisicao/config',
    name: 'RequisicaoConfig',
    component: () => import('../views/requisicao/config.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true},
  },

  {
    path: '/diagnosticos',
    name: 'Diagnosticos',
    component: () => import('../views/diagnosticos/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true},
  },
  {
    path: '/diagnosticos/form',
    name: 'diagnosticosform',
    component: () => import('../views/diagnosticos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/diagnosticos/form/:id',
    name: 'diagnosticosformEdit',
    component: () => import('../views/diagnosticos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/diagnosticos/evidencias/:id',
    name: 'diagnosticosEvidencias',
    component: () => import('../views/diagnosticos/evidencias.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/diagnosticos/answer/:id',
    name: 'diagnosticosAnswer',
    component: () => import('../views/diagnosticos/answer.vue'),
    meta: { },
  },


  {
    path: '/entregaveis',
    name: 'Entregaveis',
    component: () => import('../views/entregaveis/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/ropa',
    name: 'EntregaveisRopa',
    component: () => import('../views/entregaveis/ropa/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/riscos',
    name: 'EntregaveisRIPD',
    component: () => import('../views/entregaveis/riscos/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/conformidade',
    name: 'EntregaveisConformidade',
    component: () => import('../views/entregaveis/conformidade/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/diagnostico',
    name: 'EntregaveisDiagnostico',
    component: () => import('../views/entregaveis/diagnostico/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/agentestratamento',
    name: 'EntregaveisAgentestratamento',
    component: () => import('../views/entregaveis/agentestratamento/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/especifico',
    name: 'EntregaveisEspecifico',
    component: () => import('../views/entregaveis/especifico/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/especifico/atividades',
    name: 'EntregaveisEspecificoAtividades',
    component: () => import('../views/entregaveis/especifico/atividades.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/especifico/riscos',
    name: 'EntregaveisEspecificoRiscos',
    component: () => import('../views/entregaveis/especifico/riscos.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/especifico/projetos',
    name: 'EntregaveisEspecificoProjetos',
    component: () => import('../views/entregaveis/especifico/projetos.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/especifico/requisicao',
    name: 'EntregaveisEspecificoRequisicao',
    component: () => import('../views/entregaveis/especifico/requisicao.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/especifico/empresas',
    name: 'EntregaveisEspecificoEmpresas',
    component: () => import('../views/entregaveis/especifico/empresas.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/treinamento',
    name: 'EntregaveisEspecificoTreinamento',
    component: () => import('../views/entregaveis/treinamento/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/evidencias',
    name: 'EntregaveisEvidencias',
    component: () => import('../views/entregaveis/evidencias/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/cookies',
    name: 'EntregaveisCookies',
    component: () => import('../views/entregaveis/cookies/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/incidentes/',
    name: 'EntregaveisIncidentes',
    component: () => import('../views/entregaveis/incidentes/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/incidentes/atas',
    name: 'EntregaveisAtas',
    component: () => import('../views/entregaveis/incidentes/atas.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },
  {
    path: '/entregaveis/incidentes/formularios',
    name: 'EntregaveisFormularios',
    component: () => import('../views/entregaveis/incidentes/formularios.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true, blockCliente: true },
  },

  {
    path: '/notificacoes',
    name: 'Notificacoes',
    component: () => import('../views/notificacoes/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true },
  },

  {
    path: '/compartilhar',
    name: 'Compartilhar',
    component: () => import('../views/compartilhar/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true },
  },

  {
    path: '/consultorias',
    name: 'Consultorias',
    component: () => import('../views/consultorias/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true },
  },

  {
    path: '/contratos',
    name: 'Contratos',
    component: () => import('../views/contratos/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/contratos/form',
    name: 'contratosform',
    component: () => import('../views/contratos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/contratos/form/:id',
    name: 'contratosformEdit',
    component: () => import('../views/contratos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/contratos/assinar/:id',
    name: 'contratosAssinar',
    component: () => import('../views/contratos/assinar.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/contratos/multiplos',
    name: 'contratosMultiplos',
    component: () => import('../views/contratos/multiplos.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import('../views/cookies/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/cookies/form/:id',
    name: 'Cookiesform',
    component: () => import('../views/cookies/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/cookies/configurar/:id',
    name: 'CookiesformConfig',
    component: () => import('../views/cookies/configuracoes.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/cookies/titulares/:id',
    name: 'CookiesTitulares',
    component: () => import('../views/cookies/titulares.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/treinamentos',
    name: 'Treinamentos',
    component: () => import('../views/treinamentos/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/treinamentos/form/',
    name: 'Treinamentosform',
    component: () => import('../views/treinamentos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/treinamentos/form/:id',
    name: 'TreinamentosformEdit',
    component: () => import('../views/treinamentos/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/treinamentos-list',
    name: 'TreinamentosList',
    component: () => import('../views/treinamentos/listTreinamentos.vue'),
    meta: {requiresLogin: true },
  },
  {
    path: '/treinamentos/info/:id',
    name: 'TreinamentosInfo',
    component: () => import('../views/treinamentos/info.vue'),
  },
  {
    path: '/treinamentos/assistir/:id',
    name: 'TreinamentosAssitir',
    component: () => import('../views/treinamentos/assistir.vue'),
  },
  {
    path: '/treinamentos/answer/:id',
    name: 'TreinamentosAnswer',
    component: () => import('../views/treinamentos/answer.vue'),
  },
  {
    path: '/treinamentos/convidados/:id',
    name: 'TreinamentosConvidados',
    component: () => import('../views/treinamentos/convidados.vue'),
  },
  {
    path: '/treinamentos/corrigir/:id',
    name: 'TreinamentosCorrigir',
    component: () => import('../views/treinamentos/listCorrigir.vue'),
  },
  {
    path: '/avaliacoes/corrigir/:id',
    name: 'AvaliacoesCorrigir',
    component: () => import('../views/treinamentos/listCorrigir.vue'),
  },
  {
    path: '/treinamentos/corrigir/prova/:id',
    name: 'TreinamentosCorrigirProva',
    component: () => import('../views/treinamentos/corrigir.vue'),
  },

  {
    path: '/incidentes',
    name: 'Incidentes',
    component: () => import('../views/incidentes/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/incidentes/form/',
    name: 'Incidentesform',
    component: () => import('../views/incidentes/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/incidentes/form/:id',
    name: 'IncidentesformEdit',
    component: () => import('../views/incidentes/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/denuncias',
    name: 'Denuncias',
    component: () => import('../views/denuncias/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/denuncias/analisar/:id',
    name: 'DenunciasformEdit',
    component: () => import('../views/denuncias/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/avaliacoes',
    name: 'Avaliacoes',
    component: () => import('../views/avaliacoes/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true },
  },
  {
    path: '/avaliacoes/form/',
    name: 'Avaliacoesform',
    component: () => import('../views/avaliacoes/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },
  {
    path: '/avaliacoes/form/:id',
    name: 'avaliacoesformEdit',
    component: () => import('../views/avaliacoes/form.vue'),
    meta: { requiresLogin: true, requiresPlano: true, requiresEmpresa: true },
  },

  {
    path: '/suporte',
    name: 'Suporte',
    component: () => import('../views/suporte/index.vue'),
    meta: {requiresLogin: true },
  },
  
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/users/index.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresRoot: true, blockCliente: true},
  },
  {
    path: '/users/form',
    name: 'usersForm',
    component: () => import('../views/users/form.vue'),
    meta: {requiresLogin: true, requiresPlano: true, requiresRoot: true, blockCliente: true},
  },
  {
    path: '/users/form/:id',
    name: 'usersFormEdit',
    component: () => import('../views/users/form.vue'),
    meta: {requiresLogin: true, blockCliente: true},
  },


  {
    path: '/stripe',
    name: 'Stripe',
    component: () => import('../views/stripe/index.vue'),
    meta: {requiresLogin: true, blockCliente: true },
  },
  {
    path: '/stripe/subscribe/:priceid',
    name: 'StripeSubscribe',
    component: () => import('../views/stripe/subscribe.vue'),
    meta: {requiresLogin: true, blockCliente: true },
  },
  {
    path: '/stripe/return',
    name: 'StripeReturn',
    component: () => import('../views/stripe/return.vue'),
    meta: {requiresLogin: true, blockCliente: true },
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (
    store.state.user.profissional && 
    !store.state.user.name &&
    to.name !== 'profissionaisFormEdit'
  ) {
    //REDIRECIONAR
    alert("Complete seu cadastro!")
    next({ name: 'profissionaisFormEdit', params: { id: store.state.user._id }});
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const hasToken = localStorage.getItem('token');

  try {
    const hasEmpresa = JSON.parse(localStorage.getItem("empresa"));

    if (hasEmpresa) {
      store.commit('setEmpresa', hasEmpresa);
    }

  } catch (error) {
    console.log(error);
  }

  if (hasToken && !store.state.isLogged && store.state.user) {
    store.commit('login', JSON.parse(localStorage.getItem('user')));
    next();
  } else if (to.meta.requiresPlano && !store.state.user.cliente && !store.state.user.pago && moment(store.state.user.data).add(15,'days').endOf('days').diff(moment().endOf('days'), 'days') < 0) {
    
    const resp = await axios.get(`${process.env.VUE_APP_API}/v1/users/${store.state.user.user ? store.state.user.user : store.state.user._id}?token=${localStorage.getItem('token')}`);

    if(resp.data && resp.data.assinatura.pago && resp.data.assinatura.ativo || resp.data && resp.data.fluxoAssinaturaManual) {
      store.commit('setPago', true); // Assinatura Ativa
      next();
    } else { // Assinatura Desativa -> Caso Consultor Assinatura -> Caso profissional Login
      if(store.state.user.consultor) {
        next({name: 'Assinatura'});
      } else{
        next({name: 'AssinaturaDesativado'});
      }
    }
  } else if (to.meta.requiresLogin && !store.state.isLogged) {
    next({name: 'Login'});
  } else if(to.meta.blockCliente && store.state.user.cliente) {
    next({name: 'Projetos'});
  } else if(to.meta.blockProfissional && store.state.user.profissional) {
    next({name: 'Cadastros'});
  } else if (to.meta.requiresEmpresa && !store.state.empresa) {
    alert("Entre em uma empresa!");
    next({name: 'Painel'});
  } else if (to.meta.requiresRoot && !store.state.user.admin) {
    next({name: 'Painel'});
  } else if (to.name === 'Login' && hasToken && store.state.isLogged) {
    console.log("next");
    next(from);
  } else if(
    store.state.user.cookieBanner && 
    to.name !== 'Cookies' && 
    to.name !== 'usersFormEdit' && 
    to.name !== 'CookiesformConfig' && 
    to.name !== 'Suporte' &&
    to.name !== 'EntregaveisCookies' 
  ) {
    console.log(to.name)

    Vue.prototype.$modal.show('gostariaDeAssinar');
    next(false); // Impede a navegação
    // alert("Apenas para assinantes");
    // next({ name: "Cookies" });
  } else {
    next();
  }
});

export default router;
